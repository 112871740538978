import * as React from "react";

import Layout from "../../components/Layout";
import { StaticImage } from "gatsby-plugin-image";

const metadata = {
  title: "Soto Investigations | About",
  description: "Our experience is what makes us.",
  robots: "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  canonical: "/",
  "og:locale": "en_US",
  "og:type": "website",
  "og:title": "Soto Investigations | About",
  "og:description": "Our qualifications facilitate your criminal defense needs.",
  "og:url": "/about",
  "og:site_name": "Soto Investigations",
  "og:image": "/src/assets/logo.png",
  "article:modified_time": "2022-03-06T13:57:48+00:00",
  "twitter:card": "summary_large_image",
  "twitter:label1": "Est. reading time",
  "twitter:data1": "1 minute",
};

const IndexPage = () => (
  <Layout metadata={metadata}>
    <div className="bg-blue-navy h-full flex flex-col items-center">
      <div className="flex-grow">
        <div className="max-w-5xl text-primary flex flex-col md:flex-row mt-8 mb-4">
          <div className="mt-4 px-4 mb-4 md:mb-0 md:mt-0 md:mr-8 order-2 md:order-1">
            <div className="uppercase text-sm font-bold mb-4">About Us</div>
            <div>
              Chris Soto Investigations (CSI) was founded by Chris Soto after retiring from a 20 year
              career in Law Enforcement. Chris served onboard the USS Jouett (CG-29) in the United
              States Navy during Operation Desert Storm and Operation Southern Watch before being
              Honorably Discharged. He began his Law Enforcement career at the Bexar County Sheriff's
              Office in early 2002. In 2009 Chris joined the Cibolo Police Department where he served
              in multiple roles. He was fortunate to serve as a Patrol Officer, SWAT Operator,
              Detective (Street Crimes, Hate Crimes, Crimes Against Persons, Crimes Against Property,
              Sex Crimes and Homicide). He also served as a Patrol Sergeant, Criminal Investigation
              Division Sergeant, Less than Lethal Instructor, Taser Instructor and a Field Training
              Sergeant Coordinator. He holds certifications from FBI - Leeda Supervisor Leadership
              Institute, Incident Command/Active Shooter Coordinator and TCOLE Instructor. He has
              performed many background investigations and criminal investigations throughout his law
              enforcement career. He also worked as an Investigator for the Bexar County Criminal
              District Attorney.
            </div>
          </div>
          <div className="order-1 md:order-2 flex justify-center">
            <StaticImage
              src="../../assets/chris-soto.jpg"
              alt="Chris Soto"
              style={{ width: "400px", height: "auto" }}
              placeholder="blurred"
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center bg-gold-bright px-4 py-2">
        <div>
          <StaticImage
            src="../../assets/tali.png"
            alt="Texas Association of Licensed Investigators"
            style={{ width: "400px", height: "auto" }}
            placeholder="blurred"
          />
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
